// import './Sections.css'
import GetInviteButton from "../buttons/GetInviteButton";
import OrientationItem from "./orientations/OrientationItem";
import { useContext} from 'react';
import { GeneralContext } from '../../Contexts/General';




export default function TipsSection(props) {
    
    const {isMobile} = useContext(GeneralContext)

    var item = 0
    var items = ["#local", "#deslocamento", "#dressCode", "#preparacao"]

    function NextItem(number){
        switch (number){

            case null:
                number = 1
                break;
            case 4:
                number = 0
                break;
            case -1:
                number = 3
                break;
            default:
                break;
        }

        item = number
        let nextElement = document.getElementById(items[item]?.substring(1))
        nextElement?.scrollIntoView()
        // nextElement?.scrollIntoView({behavior:'smooth', block:isMobile?'nearest':'start'})
        
        let nextElementListener = nextElement?.addEventListener("scroll",(event)=>{
            event.preventDefault()
            
            document.removeEventListener("scroll", nextElementListener)


        },{passive:false})
        
        
    }
//
    // useEffect(()=>{
    //     document.getElementById(items[item]?.substring(1))?.scrollIntoView({behavior:'smooth', block:'start'})
    //     console.log(item)
    //     console.log(items[item])
    // },[item])


    return(
        <section id={props.id} 
        style={{backgroundColor:props.color, overflowX:'hidden',}}>

            <div id='tipsSection' style={{backgroundColor:'RGBA(0,0,255,0.0)', color:'white', width:'100%', 
                                           boxSizing:'content-box', paddingTop:'-500px'}}>
               
                <div id='slider'>
                    
                    {/* <div style={{backgroundImage:`url(${apogeu})`}}></div> */}
                    <OrientationItem id='local' 
                    title={'Local do evento'}  
                    button={<GetInviteButton style={{fontSize:isMobile?'11px':null, height:isMobile?'7%':null, width:isMobile?'32%':'22%', marginTop:isMobile?'3%':'2%'}}/>}>

                         O evento ocorrerá no espaço {isMobile?null:<br/>}
                         <b>Apogeo Nobre Alphaville</b>, {isMobile?<br/>:null}no dia {isMobile?null:<br/>}<b>23/08/24 às 19h.</b>
                    </OrientationItem>

                    <OrientationItem id='deslocamento'  
                    title={'Deslocamento'} 
                    button={<GetInviteButton style={{fontSize:isMobile?'11px':null, height:isMobile?'7%':null, width:isMobile?'32%':'22%', marginTop:isMobile?'3%':'2%'}}/>}>
                        
                        O endereço é na Av. Tamboré, {isMobile?null:<br/>}
                        1009 em Barueri/SP. {isMobile?<br/>:null}No dia do evento,{isMobile?null:<br/>}
                        consulte o tempo de trânsito.<br/>
                        Chegue com antecedência
                    </OrientationItem>

                    <OrientationItem id='dressCode'  
                    title={'Dress code'} 
                    button={<GetInviteButton style={{fontSize:isMobile?'11px':null, height:isMobile?'7%':null, width:isMobile?'32%':'22%', marginTop:isMobile?'3%':'2%'}}/>}>
                        
                        O look oficial do evento é{isMobile?null:<br/>}
                        o estilo Esporte Fino. {isMobile?<br/>:null}A cor vermelha{isMobile?null:<br/>}
                        é permitida apenas para {isMobile?<br/>:null}esmalte e maquiagem.
                    </OrientationItem>

                    <OrientationItem id='preparacao'  
                    title={'Como se preparar?'} 
                    button={<GetInviteButton style={{fontSize:isMobile?'11px':null, height:isMobile?'7%':null, width:isMobile?'32%':'22%', marginTop:isMobile?'3%':'2%'}}/>}>
                        
                        Verifique passagens aéreas{isMobile?null:<br/>}
                        ou de ônibus. Reserve hotéis ou <br/>
                        airbnb e principalmente coloque na {isMobile?null:<br/>}
                        sua agenda {isMobile?<br/>:null}o dia do evento.
                    </OrientationItem>
                </div>               

                {/* <GetInviteButton width={'32%'} onClick={props.onClick}/> */}
                <button id='btnLeft' onClick={()=>NextItem(item-1)}/>
                <button id='btnRight' onClick={()=>NextItem(item+1)}/>
            </div>

        </section>
    )
}



// #slider{
//     display:flex;
//     flex-direction: row;
//     height: 80vh;
//     width: 100vw;
//     margin: auto;
//     margin-top: 20px;
//     /* overflow-x: scroll; */
//     animation: slider-4 infinite 16s ;    
// }

// #slider li{
//     background-color: green;
//     list-style:none; 
// }

// #slider img{
//     margin: auto;
//     height: 100%;
//     width: 100vw;
//     vertical-align: top;
// }

// #slider input{
//     display: none;
// }
// #endregion