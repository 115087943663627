// import './Sections.css'
import GetInviteButton from "../buttons/GetInviteButton";
import imagem from '../../resources/personalities.png'
import { useContext } from 'react';
import { GeneralContext } from '../../Contexts/General';

export default function PersonalitySection(props) {
    
    const {isMobile} = useContext(GeneralContext)



    return(
        <section id={props.id} 
        style={{backgroundColor:props.color}}>

            <div id='personalitySection'>
             
                    
                <div id='personalityText'>
                        <h2 id='personalityTitle'>
                            Presença de Grandes<br/>
                            Personalidades
                        </h2>
                        <h5>
                            Além do nosso embaixador <strong>Felipe Titto</strong>,<br/>
                            contaremos com a presença ilustre de<br/>
                            renomados influenciadores e um super<br/>
                            show de um grande artista, não perca!
                        </h5>
                        <GetInviteButton style={{ width:isMobile?'35%':null}} onClick={props.onClick}/>
                </div>
                    <img src={imagem} />
                </div>
                
            

        </section>
    )
}