import { useContext } from 'react'
// import './GetInviteButton.css'
import { GeneralContext } from '../../Contexts/General'


export default function GetInviteButton (props){
    const {setIsFormOpen} = useContext(GeneralContext)

    function HandleOpen(open){
        setIsFormOpen(open)
    }

    return(
    <button id='inviteButton' style={props.style} onClick={()=>HandleOpen(true)}>
        {props.children||"GARANTIR ENTRADA"}
    </button>)
}