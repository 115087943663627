import { createContext, useEffect, useState } from "react";

export const GeneralContext = createContext({})

export default function GeneralProvider({children}){

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(null)


    
    
    window.onload = ()=>VerifyIsMobile()

    window.addEventListener("orientationchange", ()=>window.location.reload())

    function VerifyIsMobile(){
       
        setIsMobile(window.innerWidth < window.innerHeight)
             
    
    }

   
    useEffect(()=>{

        switch(isMobile){
            case true:
                import('../App.css')
                import('../components/Sections/Sections.css')
                import('../components/HeaderNavigationBar.css')
                import('../components/buttons/GetInviteButton.css')
                import('../components/buttons/HotelButton.css')
                import('../components/Sections/orientations/OrientationItem.css')
                import('../components/buttons/DressCodeButton.css')
                import('../components/Form.css')
                import('../components/buttons/RegisterButton.css')
                import('../components/Sections/FAQItem/FAQItem.css')  
                break;

            case false:
                import ('../AppOriginal.css')
                import('../components/Sections/SectionsOriginal.css')
                import('../components/HeaderNavigationBarOriginal.css')
                import('../components/buttons/GetInviteButtonOriginal.css')
                import('../components/buttons/HotelButtonOriginal.css')
                import('../components/buttons/DressCodeButtonOriginal.css')
                import('../components/Sections/orientations/OrientationItemOriginal.css')
                import('../components/FormOriginal.css')
                import('../components/buttons/RegisterButtonOriginal.css')
                import('../components/Sections/FAQItem/FAQItemOriginal.css')
                break;

            default:
                break;
        } 
    }, [isMobile])
    

    

    return(
        <GeneralContext.Provider value={{isFormOpen, setIsFormOpen, isMobile}}>
            <div style={{width:'100vw',}} onLoad={()=>{
                
               VerifyIsMobile()
                
            }}>
                {children}
            </div>
            
            
        </GeneralContext.Provider>
    )

}
