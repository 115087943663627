// import "./HotelButton.css"


export default function HotelButton(props){

    return(
        <a target='blank'
        href={props.href}>
            <div className='hotelButtonView' id={props.id} style={props.style}>
                <p>{props.children}</p>
            </div>
        </a>
    )
}