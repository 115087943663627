// import './Sections.css'
import { useContext } from "react";
import GetInviteButton from "../buttons/GetInviteButton";
import HotelButton from '../buttons/HotelButton';
import { GeneralContext } from "../../Contexts/General";


export default function HotelsSection(props) {

    const {isMobile} = useContext(GeneralContext)
    
    return(
        <section id={props.id} >

            <div className='hotelsContainer' style={{color:'white'}}>
                                            
                <div id="hotelsText">
                    <h2 style={{marginBottom:'5%'}} id='headline'>
                        Hotéis próximos ao evento:
                    </h2>
                    <p id='description'>
                        Precisa de uma estadia?<br/>
                        {isMobile?<><br/></>:null}
                        
                        Sugerimos os hotéis Blue Tree Premium, Comfort Suites Alphaville e Ibis Budget Tamboré, todos em Alphaville - SP.{isMobile?null:<br/>}
                        Você também pode procurar por estadias em Barueri no site ou app Airbnb.
                    </p>
                </div>

                <div id='buttons'>
                    <div id='hotelsList'>
                        <HotelButton 
                        id="bluetree"
                        href={"https://www.google.com/travel/search?q=hoteis%20proximos%20do%20apogeo%20alphaville&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C10210223%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72462234%2C72470899%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72486593%2C72494250%2C72513513%2C72536387%2C72549171%2C72570850%2C72597757%2C72602734%2C72605013%2C72606237%2C72616120%2C72619927%2C72620306%2C72620962%2C72624691%2C72626061%2C72634629%2C72648288&hl=pt-BR&gl=br&cs=1&ssta=1&ts=CAESCAoCCAMKAggDGhwSGhIUCgcI6A8QBhgeEgcI6A8QBxgBGAEyAhAAKgcKBToDQlJM&qs=CAEyFENnc0lwcjdiNE8zRHVfR0tBUkFCOApCCRHNoeYdKw0LhkIJEYPtFQfZQLJQQgkR5F6daf-8EcxaXjJcqgFZEAEqCiIGaG90ZWlzKAwyHxABIhuYIw9OIQv9VsAFvHsznJ4zx7RMfdTOMH9fuywyKBACIiRob3RlaXMgcHJveGltb3MgZG8gYXBvZ2VvIGFscGhhdmlsbGU&ap=aAG6AQhvdmVydmlldw&ictx=111&ved=0CAAQ5JsGahcKEwiQro675oeHAxUAAAAAHQAAAAAQBQ"}>
                            Blue Tree
                        </HotelButton>

                        <HotelButton 
                        id="comfortsuites"
                        href={"https://www.google.com/travel/search?q=hoteis%20proximos%20do%20apogeo%20alphaville&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C10210223%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72462234%2C72470899%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72486593%2C72494250%2C72513513%2C72536387%2C72549171%2C72570850%2C72597757%2C72602734%2C72605013%2C72606237%2C72616120%2C72619927%2C72620306%2C72620962%2C72624691%2C72626061%2C72634629%2C72648288&hl=pt-BR&gl=br&cs=1&ssta=1&ts=CAESCAoCCAMKAggDGhwSGhIUCgcI6A8QBhgeEgcI6A8QBxgBGAEyAhAAKgcKBToDQlJM&qs=CAEyE0Nnb0lfZVgxdXZHZTd1QmVFQUU4CkIJEc2h5h0rDQuGQgkRg-0VB9lAslBCCRHkXp1p_7wRzFpeMlyqAVkQASoKIgZob3RlaXMoDDIfEAEiG5gjD04hC_1WwAW8ezOcnjPHtEx91M4wf1-7LDIoEAIiJGhvdGVpcyBwcm94aW1vcyBkbyBhcG9nZW8gYWxwaGF2aWxsZQ&ap=aAG6AQhvdmVydmlldw&ictx=111&ved=0CAAQ5JsGahcKEwjw4JmU-IeHAxUAAAAAHQAAAAAQDA"}>
                            Comfort Suites
                        </HotelButton>

                        <HotelButton 
                        id="ibis"
                        href={"https://www.google.com/travel/search?q=hoteis%20proximos%20do%20apogeo%20alphaville&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C10210223%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72462234%2C72470899%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72486593%2C72494250%2C72513513%2C72536387%2C72549171%2C72570850%2C72597757%2C72602734%2C72605013%2C72606237%2C72616120%2C72619927%2C72620306%2C72620962%2C72624691%2C72626061%2C72634629%2C72648288&hl=pt-BR&gl=br&cs=1&ssta=1&ts=CAESCAoCCAMKAggDGh4SHBIUCgcI6A8QBhgeEgcI6A8QBxgBGAEyBAgAEAAqBwoFOgNCUkw&qs=CAEyE0Nnb0lzTFNFM2FMa3NmRWRFQUU4CkIJEc2h5h0rDQuGQgkRg-0VB9lAslBCCRHkXp1p_7wRzFpeMlyqAVkQASoKIgZob3RlaXMoDDIfEAEiG5gjD04hC_1WwAW8ezOcnjPHtEx91M4wf1-7LDIoEAIiJGhvdGVpcyBwcm94aW1vcyBkbyBhcG9nZW8gYWxwaGF2aWxsZQ&ap=MABoAboBB2RldGFpbHM&ictx=111&ved=0CAAQ5JsGahcKEwjA0eXC5oeHAxUAAAAAHQAAAAAQMg"}>
                            Ibis Budget
                        </HotelButton>
                    </div>
                    <GetInviteButton style={{width:'22%', minWidth:isMobile?'130px':'185px'}}onClick={props.onClick}/>
            </div>

            </div>

            


        </section>
    )
}


