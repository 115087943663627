
// import "./OrientationItem.css"

import { useContext } from "react";
import { GeneralContext } from "../../../Contexts/General";

export default function OrientationItem(props) {
    const {isMobile} = useContext(GeneralContext)

    return (
        <>
            <div id={props.id} className="orientationItem" style={props.style}>
                <h3 style={{marginBottom:isMobile?'null':'1%'}}>{props.title}</h3>
                <p>{props.children}</p>
                {props.button}
            </div>
        </>
    );
}
