// import "./DressCodeButton.css"


export default function DressCodeButton(props){

    return(
        <a target='blank'
        href={props.href}>
            <div className='dressButtonView' id={props.id} style={props.style}>
                <p>{props.children}</p>
            </div>
        </a>
    )
}