// import "./FAQItem.css"
import xmark from "../../../resources/xmark.png"
import plusmark from "../../../resources/plusmark.png"
import { useState } from "react"

export default function FAQItem(props){
    const [isOpen, setIsOpen] = useState(false)


    function handleSetIsOpen(isopen){
        setIsOpen(isopen)
    }
    return(
        <button onClick={()=>handleSetIsOpen(!isOpen)} className="FAQItem" style={{minHeight:isOpen?'140px':'60px'}}>
            
            <div id="question" >
               {props.question}
                <img alt="button arrow" src={isOpen?xmark:plusmark}/>
            </div>
            <div style={{width:'100%', height:'2px', backgroundColor:'rgba(165, 165, 255, 0.15)'}}/>
            <div id="response" style={{display:isOpen?'flex':'none', zIndex:'1'}}>
                <p>{props.children}</p>
            </div>
            <div style={{display:!isOpen?"none":"inherit", width:'100%', height:'2px', backgroundColor:'rgba(165, 165, 255, 0.15)', marginTop:'2%'}}/>
        </button>
    )
    
}