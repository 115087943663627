// import './Sections.css'
import GetInviteButton from "../buttons/GetInviteButton";
import { useContext } from 'react';
import { GeneralContext } from '../../Contexts/General';


export default function VideoSection(props) {

    const {isMobile} = useContext(GeneralContext)
 
    return(
        <section id={props.id} 
        style={{backgroundColor:props.color, width:'100%', overflow:'hidden' }}>

            <div id='videosSection' style={{backgroundColor:'RGBA(0,0,255,0.0)', color:'white',width:'100%', 
                                            padding:isMobile?'0px':'35px', boxSizing:'content-box', overflow:'hidden'}}>
                            
                <h3 style={{color:'rgba(149, 197, 255, 1)', marginTop:isMobile?'50px':'20px', marginBottom:isMobile?'10px':'20px', fontFamily:'Inter'}}>
                    Confira o que você {isMobile?<br/>:null}pode esperar do evento:
                </h3>

                <div id='videosContainer'>
                    <iframe width="445" height="791" src="https://www.youtube.com/embed/k-FkI5LSVpQ" title="Gilberto - Convite" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen/>

                    <iframe width="445" height="791" src="https://www.youtube.com/embed/TSA_Mi-TV0Y" title="Romário - Convite" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen></iframe>
                </div>        
                <div id="names" style={{fontSize:isMobile?"54%":'100%'}}>
                    <p id="gilberto" style={{textAlign:'center',}}>
                        Gilberto Jr. - CEO da OwemPay
                    </p>        
                    <p id="romario" style={{textAlign:'center', }}>
                        Romário Carnero - CTO da<br/>OwemPay
                    </p>        
                </div>

                <GetInviteButton style={{width:'20%', minWidth:isMobile?'150px':'250px', marginBottom:'5%', marginTop:isMobile?'5%':'2.5%'}} onClick={props.onClick}/>  
            </div>
        </section>
    )
}

