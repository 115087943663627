import HeaderNavigationBar from './components/HeaderNavigationBar';
// import './App.css';
import { Route, Routes} from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import InviteSection from './components/Sections/InviteSection';
import VideoSection from './components/Sections/VideoSection';
import PersonalitySection from './components/Sections/PersonalitySection';
import TipsSection from './components/Sections/TipsSection';
import WhySection from './components/Sections/WhySection';
import HotelsSection from './components/Sections/HotelsSection';
import DressCodeSection from './components/Sections/DressCodeSection';
import FAQSection from './components/Sections/FAQSection';
import Footer from './components/Sections/FooterSection';
import Form from './components/Form';
import GeneralProvider from './Contexts/General';
import "normalize.css"
import BackToTopButton from './components/buttons/BackToTopButton';


function App() {

  return (
  
    <GeneralProvider>
      <BrowserRouter location={window.location}>
      
        <HeaderNavigationBar/>
      
        <Routes>
          <Route  path='/' Component={()=>
          <div className='Container' style={{overflow:'hidden'}}>           
            <div style={{height:'87px'}}></div>
            <Form isOpen/>
            <InviteSection id="invite"/>
            <VideoSection id="videos"/>
            <PersonalitySection id="personality"/>
            <TipsSection id="tips"/>
            <WhySection id="why"/>
            <HotelsSection id="hotels"/>
            <DressCodeSection id="dresscode"/>
            <FAQSection id="faq"/>
            <BackToTopButton/>
            <Footer id="footer"/>        
          </div>}/>
        </Routes>
      </BrowserRouter>
    </GeneralProvider>
  );
}

export default App;