import { useContext, useEffect, useState } from "react";
// import './HeaderNavigationBar.css'
import { Link, useLocation } from "react-router-dom";
import logo from "../resources/logoowempay.svg"
import { GeneralContext } from "../Contexts/General";



export default function HeaderNavigationBar (){

    const location = useLocation();
    const [visible, setVisible] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    const {isMobile} = useContext(GeneralContext)

    
    

    useEffect(()=>{
    let block = location.hash === "#invite" || location.hash === ""?'end':'start'
        document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior:'smooth', block:block})
    },[location])
    

    function handleSetVisible(isVisible){
        setVisible(isVisible)
    }   

    window.onscrollend  = ()=>setScrollY(window.scrollY)

    return(
       <>
       
        {isMobile?null:
            <div onMouseOver={(mouseEvent)=>handleSetVisible(true)}
            style={{pointerEvents:visible?'none':'all', position:'fixed', zIndex:2, backgroundColor:"rgba(255,0,255,0.0)", height:'87px', width:'100vw'}}>

            </div>
        }

        <header id="header"
        onMouseLeave={(mouseEvent)=>handleSetVisible(false)} 
        style={{position:visible? window.scrollY < 100?'fixed':'fixed':'absolute', 
        background:scrollY < 100?'rgba(0,0,0,0)': isMobile === false?"linear-gradient(90deg, rgba(30, 36, 43, 1) 30%, rgba(0,0,190,1) 90%)":'rgba(0,0,0,0)'}}>
            
            <Link style={{color:'white'}} to={"/#invite"}>
                <img alt="owempay logomark" id="headerLogo" src={logo}/>
            </Link>
            
            
            {
                isMobile?null:
                <nav>
                    <Link style={{color:'white'}} to={"/#videos"}>
                        Convite
                    </Link>
                    <Link style={{color:'white'}} to={"/#personality"}>
                        Atrações
                    </Link>
                    <Link style={{color:'white'}} to={"/#tips"}>
                        Orientações
                    </Link>
                    <Link style={{color:'white'}} to={"/#why"}>
                        Por que participar?
                    </Link>
                    <Link style={{color:'white'}} to={"/#hotels"}>
                        Hotéis
                    </Link>
                    <Link style={{color:'white'}} to={"/#dresscode"}>
                        Dress Code
                    </Link>
                    <Link style={{color:'white'}} to={"/#faq"}>
                        FAQ
                    </Link>
                </nav>
            }            
        </header>
       </>
    )

}