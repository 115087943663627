import FAQItem from "./FAQItem/FAQItem"
// import "./Sections.css"


export default function FAQSection (props){


    return (

        <div id={props.id}>
            {/* <div id="container"> */}
                <p id="faqTitle">
                    FAQ
                    <h5 id="faqSubTitle">
                        Perguntas frequentes
                    </h5>
                </p>

                <div className="faqArea" style={{width:'85%'}}>
                    <div style={{width:'100%', height:'2px', backgroundColor:'rgba(165, 165, 255, 0.15)'}}/>
                    <FAQItem question=" A participação no evento é obrigatória?">
                        Não é obrigatória, mas sugerimos sua participação. Será uma noite com muitas experiências e convidados especiais!
                    </FAQItem>
                    <FAQItem question="Qual é a melhor região para reservar o meu hotel?">
                        Com certeza em Alphaville, mas você pode procurar por hotéis em Barueri e Santana de Parnaíba. Consulte os hotéis indicados. Sugerimos também que faça uma busca por estadia no site ou app Airbnb.
                    </FAQItem>
                    {/* <FAQItem question="Qual é o melhor aeroporto para desembarcar?">
                        O aeroporto de Congonhas (CGH) é o mais próximo. Caso desembarque no aeroporto de Guarulhos (GRU), aconselhamos separar um valor para o deslocamento (Uber, Táxi, aluguel de carros, entre outros serviços disponíveis).
                    </FAQItem> */}
                    <FAQItem question="Qual será o dress code do evento?">
                        Será o elegantíssimo esporte fino. Use peças como blazer, camisa social, calça de alfaiataria, jeans e sapato social para os homens. Para as mulheres, opte por peças como vestidos longos e macacões. A cor vermelha será permitida apenas para maquiagem e unhas. Consulte as referências que colocamos para te inspirar no look. 
                    </FAQItem>
                    <FAQItem question="O evento contará com alimentação?">
                        Fique despreocupado. O evento contará com petiscos, mesa de frios, bebidas e um chef servindo comida japonesa. Se for consumir bebida alcoólica, não dirija.
                    </FAQItem>
                    <FAQItem question="Terá estacionamento no local?">
                        Sim. Teremos vagas em quantidade limitada, portanto, chegue com antecedência para garantir sua vaga!
                    </FAQItem>
                    <FAQItem question="Como funcionará o credenciamento?">
                        Ao chegar, dirija-se à recepção, onde nossas concierges irão fazer a confirmação da sua entrada.
                    </FAQItem>
                    
                </div>
            {/* </div> */}
            
        </div>
    )

}