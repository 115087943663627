// import './Sections.css'
import GetInviteButton from "../buttons/GetInviteButton";
import DressCodeButton from '../buttons/DressCodeButton';
import { useContext } from "react";
import { GeneralContext } from "../../Contexts/General";


export default function DressCodeSection(props) {

    const {isMobile} = useContext(GeneralContext)
    
    return(
        <section id={props.id} >

            <div className='dressContainer' style={{color:'white'}}>
                                            
                <div id="dressText">
                    <h2 id='headline'>
                        O que vestir no dia do evento?
                    </h2>
                    <p id='description'>
                        Adapte-se ao nosso dress code no estilo {isMobile?<b>Esporte Fino</b>:<strong>Esporte Fino</strong>}, esbanjando elegância e casualidade.
                    </p>
                    <p id='description'>
                        Ressaltamos e recomendamos a {isMobile?<b>não utilização da cor vermelha</b>:<strong>não utilização da cor vermelha</strong>} para roupas, sapatos e{isMobile?null:<br/>}
                        acessórios, sendo permitida apenas para maquiagens e unhas.
                    </p>
                    <p id='description'>
                        Confira os looks abaixo para se inspirar e brilhar na noite do evento:
                    </p>
                </div>

                <div id='buttons'>
                    <div id='dressList'>
                        <DressCodeButton
                        id="masculino"
                        href={"https://www.canva.com/design/DAGJtlWAxUI/juZFSy8T7Kppa1EOPsRl5Q/view?utm_content=DAGJtlWAxUI&utm_campaign=designshare&utm_medium=link&utm_source=editor"}>
                            Clique aqui <br/>
                            para ver mais
                        </DressCodeButton>

                        <DressCodeButton 
                        id="feminino"
                        href={"https://www.canva.com/design/DAGJteaHZWU/tvyO2xfwWgatdCyutB1-ZQ/view?utm_content=DAGJteaHZWU&utm_campaign=designshare&utm_medium=link&utm_source=editor"}>
                            Clique aqui <br/>
                            para ver mais
                        </DressCodeButton>
                    </div>
                    <GetInviteButton style={{width:'22%', minWidth:isMobile?'150px':'185px',  marginTop:isMobile?'12%':'7%', zIndex:'5'}}onClick={props.onClick}/>
            </div>

            </div>

            


        </section>
    )
}


