import { useContext, useState } from "react"
// import "./Form.css"
import RegisterButton from "./buttons/RegisterButton"

import xmark from "../resources/xmark.png"
import { GeneralContext } from "../Contexts/General"

export default function Form(props){

    let formTypesObj = {
        "employee":"empregados",
        "vip":"vip"
    }
    const [formType, setFormType] = useState("employee")
    const {isFormOpen, setIsFormOpen} = useContext(GeneralContext)
    const [sendingStatus, setSendingStatus] = useState("no")
    const [convidado, setConvidado] = useState({
        "whoInvited":'',
        "name":'',
        "cpf":'',
        "email":'',
        "tel":'',
        "state":'',
        "city":'',
        "cep":'',
        "hasGuests":'não',
        "amountOfGuests":'',
        "guestOneName":'',
        "guestOneCPF":'',
        "guestOneEmail":'',
        "guestTwoName":'',
        "guestTwoCPF":'',
        "guestTwoEmail":'',

    })
    const {isMobile} = useContext(GeneralContext)

    const token = process.env.REACT_APP_TOKEN 
    const planilhaID = process.env.REACT_APP_ID_PLANILHA
    const url = process.env.REACT_APP_SETCONVIDADO_URL

    var regex = {
        "onlyNumbers":/^[0-9]$/,
        "onlyElevenNumbers":/^\d{11}$/,
        "onlyPhoneDigits":/^[0-9().-]+$/
    }

    var ArrayStringMasks = {
        "tel":["(","","",")","","","","","","-","","","","",],
        "cpf":["","","",".","","","",".","","","","-","","",],
        "guestOneCPF":["","","",".","","","",".","","","","-","","",],
        "guestTwoCPF":["","","",".","","","",".","","","","-","","",],
        "cep":["","","","","","-","","",""],   
    }

    let listener = document.addEventListener("keydown", (event)=>{

        if(event.key !== "Escape")
            return
        
        HandleOpen(false)
        document.removeEventListener("keydown", listener)
        
    })

    function SetConvidadoInfo(key, value){

        if(Object.keys(ArrayStringMasks).find((item)=>item === key))
            value = Mask(value, key)
       
        setConvidado(prevState => ({
            ...prevState,
            [key]:value,
        })) 

              
    }

    function Mask(string="", mask){
        
        let numberString = ""
        string.split("").map((value)=>{
            if(value.match(regex.onlyNumbers))
                numberString += value

            return null;
        })

        let newString = ""


        let arrayMask = ArrayStringMasks[mask]

        let numberArray = numberString.split("")
        
        for (let i = 0; i < numberArray.length; i ++){
            
            for (let m = 0; m < arrayMask.length; m++){
                if (arrayMask[m] === ""){
                    arrayMask[m] = numberArray[i]
                    break;
                }
            }
        }

        arrayMask.map(value => {
            newString = newString.concat(value)
            return null
        })

        newString = newString.trim()
        if (!regex.onlyNumbers.test(newString.charAt(newString.length - 1))){
            newString =  newString.substring(0, newString.length - 1)
        }

        return newString
    }

    async function SendData(){
        setSendingStatus("pending")
     await fetch(url, {

            method:"POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body:JSON.stringify({
                "values":Object.values(convidado),
                "config": {
                    "range":`${formTypesObj[formType]}!B:B`,
                    "spreadsheetId":planilhaID
                }                    
            }),
        },
        ).then(()=>{setSendingStatus("success"); setTimeout(()=>{setSendingStatus("no"); HandleOpen(false)}, 3000)})
        .catch((error)=>{setSendingStatus("error"); setTimeout(()=>{setSendingStatus("no"); HandleOpen(false)}, 5000)})
    }
  

    function HandleSetForm(type){
        setFormType(type)
    }

    function HandleOpen(open){
        setIsFormOpen(open)
    }
    
    const form = <>
             <div className="formsType">
                    <button onClick={()=>HandleSetForm("employee")}
                    style={{backgroundColor:formType==="employee"?'rgba(77, 136, 255, 1)':'rgba(0,0,255,0.0)', borderRadius:'33px 0px 0px 33px'}}>
                        Colaboradores
                    </button>
                    <button onClick={()=>HandleSetForm("vip")}
                    style={{backgroundColor:formType==="vip"?'rgba(77, 136, 255, 1)':'rgba(0,0,255,0.0)', borderRadius:'0px 33px 33px 0px'}}>
                        VIP
                    </button>
                </div>
                { formType==="vip"?<label form="form1" id="whoInvite" style={{width:isMobile?null:'86.5%'}}>Foi convidado por quem?
                                <input onChange={event => SetConvidadoInfo("whoInvited", event.currentTarget.value)} required form="form1" type="text" placeholder="Nome Completo"/>
                        </label>:null}
                <form  id="form1" onSubmit={(event)=>{
                    event.preventDefault()
                    SendData()
                    }}>
                    <div id="formLeft">
                       
                        <label>Nome Completo
                            <input onChange={event => SetConvidadoInfo("name", event.currentTarget.value)} required type="text" placeholder="Nome Completo"/>
                        </label>
                        <label>CPF
                            <input value={convidado.cpf} onChange={event => SetConvidadoInfo("cpf", event.currentTarget.value)} required type="text" placeholder="000.000.000-00"/>
                        </label>
                        <label>Email
                            <input onChange={event => SetConvidadoInfo("email", event.currentTarget.value)} required type="email" placeholder="Email"/>
                        </label>
                        <label>Telefone
                            <input value={convidado.tel} onChange={event => SetConvidadoInfo("tel", event.currentTarget.value)} required type="tel" placeholder="(00) 00000-0000"/>
                        </label>
                        
                    </div>
                    <div id="formMiddle">
                        <label>Estados
                            <select onChange={event => SetConvidadoInfo("state", event.currentTarget.value)} required name="Estados">
                                <optgroup style={{fontFamily:"Arial"}} label="Estados">
                                    <option style={{fontFamily:"Arial"}} value="AC">Acre</option>
                                    <option style={{fontFamily:"Arial"}} value="AL">Alagoas</option>
                                    <option style={{fontFamily:"Arial"}} value="AP">Amapá</option>
                                    <option style={{fontFamily:"Arial"}} value="AM">Amazonas</option>
                                    <option style={{fontFamily:"Arial"}} value="BA">Bahia</option>
                                    <option style={{fontFamily:"Arial"}} value="CE">Ceará</option>
                                    <option style={{fontFamily:"Arial"}} value="DF">Distrito Federal</option>
                                    <option style={{fontFamily:"Arial"}} value="ES">Espírito Santo</option>
                                    <option style={{fontFamily:"Arial"}} value="GO">Goiás</option>
                                    <option style={{fontFamily:"Arial"}} value="MA">Maranhão</option>
                                    <option style={{fontFamily:"Arial"}} value="MT">Mato Grosso</option>
                                    <option style={{fontFamily:"Arial"}} value="MS">Mato Grosso do Sul</option>
                                    <option style={{fontFamily:"Arial"}} value="MG">Minas Gerais</option>
                                    <option style={{fontFamily:"Arial"}} value="PA">Pará</option>
                                    <option style={{fontFamily:"Arial"}} value="PB">Paraíba</option>
                                    <option style={{fontFamily:"Arial"}} value="PR">Paraná</option>
                                    <option style={{fontFamily:"Arial"}} value="PE">Pernambuco</option>
                                    <option style={{fontFamily:"Arial"}} value="PI">Piauí</option>
                                    <option style={{fontFamily:"Arial"}} value="RJ">Rio de Janeiro</option>
                                    <option style={{fontFamily:"Arial"}} value="RN">Rio Grande do Norte</option>
                                    <option style={{fontFamily:"Arial"}} value="RS">Rio Grande do Sul</option>
                                    <option style={{fontFamily:"Arial"}} value="RO">Rondônia</option>
                                    <option style={{fontFamily:"Arial"}} value="RR">Roraima</option>
                                    <option style={{fontFamily:"Arial"}} value="SC">Santa Catarina</option>
                                    <option style={{fontFamily:"Arial"}} value="SP">São Paulo</option>
                                    <option style={{fontFamily:"Arial"}} value="SE">Sergipe</option>
                                    <option style={{fontFamily:"Arial"}} value="TO">Tocantins</option>
                                </optgroup>
                            </select>
                        </label>
                        <label>Cidade
                            <input onChange={event => SetConvidadoInfo("city", event.currentTarget.value)} required type="text" placeholder="Cidade"/>
                        </label>
                        <label>CEP
                            <input value={convidado.cep} onChange={event => SetConvidadoInfo("cep", event.currentTarget.value)} required type="numer" placeholder="00000-000"/>
                        </label>
                        <label>Vai levar convidados?
                            <select onChange={event => SetConvidadoInfo("hasGuests", event.currentTarget.value)} required defaultValue={false} name="LevaConvidados">
                                <option style={{fontFamily:"Arial"}} value={true}>Sim</option>
                                <option style={{fontFamily:"Arial"}} value={false}>Não</option>
                            </select>
                        </label>
                    </div>
                    <div id="formRight">
                        <label>Se sim, quantos?
                            <input onChange={event => SetConvidadoInfo("amountOfGuests", event.currentTarget.value)} max={2} min={0} type="number" placeholder="Quantos Convidados"/>
                        </label>
                        <div id="convidados">
                            <div id="left">
                                <label>Nome do convidado
                                    <input onChange={event => SetConvidadoInfo("guestOneName", event.currentTarget.value)} type="text" placeholder="Nome Convidado"/>
                                </label>
                                <label>CPF do convidado
                                    <input value={convidado.guestOneCPF} onChange={event => SetConvidadoInfo("guestOneCPF", event.currentTarget.value)} type="text" placeholder="000.000.000-00"/>
                                </label>
                                <label>Email do convidado
                                    <input onChange={event => SetConvidadoInfo("guestOneEmail", event.currentTarget.value)} type="email" placeholder="Email Convidado"/>
                                </label>
                            </div>
                            <div id="right">
                                <label>Nome do convidado
                                    <input onChange={event => SetConvidadoInfo("guestTwoName", event.currentTarget.value)} type="text" placeholder="Nome Convidado"/>
                                </label>
                                <label>CPF do convidado
                                    <input value={convidado.guestTwoCPF} onChange={event => SetConvidadoInfo("guestTwoCPF", event.currentTarget.value)} type="text" placeholder="000.000.000-00"/>
                                </label>
                                <label>Email do convidado
                                    <input onChange={event => SetConvidadoInfo("guestTwoEmail", event.currentTarget.value)} type="email" placeholder="Email Convidado"/>
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </form>
                
                <RegisterButton form="form1" style={{width:isMobile?'50%':'20%', transform:'scale(0.7)', fontSize:('20px'), marginBottom:"-25px", marginTop:isMobile?'10px':null}}/>
                
        </>
    const loader =  <div className="loader"/> 
    const success = <div className="finishSendText">

                        Enviado 🙌

                    </div>
    const error = <div className="finishSendText">

                        Não foi possível realizar o registro. <br/>
                        Tente novamente mais tarde

                    </div>


    const content = {
        "no":form,
        "pending":loader,
        "success":success,
        "error":error
    }

    return(
        <dialog className="modal" style={{display:isFormOpen?'inherit':'none'}}>
            
            <div  id="container" >
                <button id="close"  onClick={()=>HandleOpen(false)}
                style={{display:'flex', justifyContent:'color:', position:'absolute', top:isMobile?'2%':'7%', right:isMobile?'6%':'4%',
                backgroundColor:'rgba(37, 42, 49, 1)', borderWidth:0, borderRadius:'100%', cursor:'pointer'}}>
                    <img alt="close button X mark" style={{opacity:'0.5'}} src={xmark}>
                    </img>
                </button>

                {content[sendingStatus]}
                    
            </div>
        
        </dialog>
    )
}