// import './Sections.css'
import { useContext } from "react";
import GetInviteButton from "../buttons/GetInviteButton";
import { GeneralContext } from "../../Contexts/General";


export default function WhySection(props) {
    const {isMobile} = useContext(GeneralContext)
    
    return(
        <section id={props.id} >

            <div style={{color:'white'}}>
                                            
                <div id="whyText">
                    <p id='headline'>
                        Por que participar<br/> 
                        desse momento único? 
                    </p>
                  
                    <p id='description'>
                        Você fará parte da história da empresa, que marca a <br/>
                        inauguração da nossa sede.
                    </p>
                    <p id='description'>
                        É uma ótima oportunidade para conhecer outros colaboradores<br/> 
                        e fazer parcerias e claro viver uma experiência única, com diversas<br/> 
                        surpresas que vão de brindes a um show de um grande artista. 
                    </p>
                    <GetInviteButton style={{width:isMobile?'65%':'37%'}}onClick={props.onClick}/>
                </div>
               
                
            </div>

        </section>
    )
}


