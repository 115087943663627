// import './Sections.css'
import GetInviteButton from "../buttons/GetInviteButton";
import { useContext } from 'react';
import { GeneralContext } from '../../Contexts/General';


export default function InviteSection(props) {
    
    const {isMobile} = useContext(GeneralContext)
    

    return(
        <section id={props.id} >

            <div id='container' style={{color:'white', paddingLeft:isMobile?'0px':'7%'}}>
                                            
                <div id="inviteText">
                    <p id='date'>
                        23 de agosto - 2024 em Alphaville - SP às 19h
                    </p>
                    <p id='headline'>
                        Faça Parte Do Início<br/>
                        De Uma Grande História
                    </p>
                    <p id='description'>
                        Não perca o evento mais esperado da OwemPay<br/>
                        com <b>convidados especiais, surpresas e muito mais.</b>
                    </p>
                    <GetInviteButton style={{width:isMobile?'55%':'35%'}}onClick={props.onClick}/>
                </div>
                
            </div>

        </section>
    )
}


