// import "./Sections.css"
import logo from "../../resources/logoowempay.svg"
import instaLogo from "../../resources/icon-instagram.svg"

export default function Footer(props){



    return(
        <div id={props.id}>
             <div style={{width:'100%', height:'1px', backgroundColor:'rgba(165, 255, 255, 0.25)'}}/>
            <div id="container">
                <div id="half-left">
                    <div>
                    <p id="companyName"> OWEM PAY INTERMEDIADORA DE PAGAMENTOS LTDA</p>
                    <p id="cnpj">37.839.059/0001-88</p>
                    </div>

                    <a target="blank"
                    style={{textDecoration:'none', color:'rgba(188, 188, 188, 1)'}} href="https://www.instagram.com/owempay?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                        <img src={instaLogo}/> @owempay
                    </a>
                </div>
                <div id="half-right">
                    <img src={logo}/>
                    <p>©2021 OwemPay. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}